/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable indent */
/* eslint-disable no-tabs */
export const ServerStatus = {
    Ok: 200,
    created: 201,
    InternalServerError: 500
};

export const SuccessMessages = {
    CustomerCreated: 'Customer created successfully.'
};

export const ErrorMessages = {
    CustomerCreated: 'Error occured while creating customer.'
};

export const InvitationEmailSubject = 'Empower Media Agent - Account Activation';
export const InvitationBody = 'Thank you for your interest in Empower Media Agent. Please use the activation link and code below to access the platform to learn more about Media Agent packages, pricing, and how it works.';
export const RequestRevisionSubject = 'Revision Requested on Media Recommendation Plan';
export const AlertMessages = {
    Common: {
        RequiredUserName: 'Please enter user name.',
        ValidEmailAddress: 'Please enter valid email address.',
        ValidateUserName: 'User name should be alpha numeric'
    },
    Invitations: {
        RequiredFirstName: 'Please enter first name',
        RequiredLastName: 'Please enter last name',
        RequiredCompanyName: 'Please enter legal name.',
        RequiredDBA: 'Please enter company name',
        RequiredEmail: 'Please enter business email address.',
        RequiredEmailSubject: 'Please enter email subject.',
        RequiredEmailBody: 'Please enter email body.',
        ValidContactNumber: 'Contact number is invalid.',
        RangeUserName: 'User name should be greater than 6 character and less than 26 character.',
        ValidUserName: 'User name should be alpha numeric',
        ExistingErrorMessage: 'There’s already an existing account with the same ',
        MinUserName: 'User name should have minimum 5 characters.',
        MaxUserName: 'User name should have maximum 25 characters.',
        EndDateRange: 'End date should not be less than start date.',
        StartDateRange: 'Start date should not be greater than end date',
        CustomerSuccessManager: 'Please select customer success manager.',
        campaignDateError: 'Please select date.'
    },
    Login: {
        RequiredPassword: 'Please enter password.'
    },
    LocationForm: {
        BusinessLegalName: 'Please enter business legal name.',
        CompanyName: 'Please enter company name.',
        ContactNumber: 'Please enter contact number.',
        LocationName: 'Please enter location name.',
        FirstName: 'Please enter first name.',
        LastName: 'Please enter last name.',
        Year: 'Please enter duration.',
        Address1: 'Please enter address 1.',
        Address2: 'Please enter address 2.',
        City: 'Please enter city.',
        State: 'Please select state.',
        Zip: 'Please enter zip.',
        ValidZip: 'Please enter valid zip code.',
        Budget: 'Entered amount must not be more than total budget.',
        MediaBudget: 'Amount entered must equal total budget.'
    },
    MediaMix: {
        RequiredMediaChannel: 'Please select atleast one  media channel.',
        RequiredAllChannelPreferenceIndicator: 'Please select atleast one option in all media channel.',
        RequiredIncludeNoPreference: 'Please select atleast one media channel with either “Include” or “No Preference”',
        RequiredAsset: ': Available Creative is required.'
    },
    Company: {
        RequiredAccountsPaybleName: 'Please enter accounts payable contact name.',
        RequiredAccountsPaybleEmail: 'Please enter accounts payable email address.'
    },
    RewardPoints: {
        AddPointsRequired: 'Please enter points to be awarded.',
        RewardPointsNote: 'Please enter note.',
        AddZeroPoints: 'Points awarded should be greater than zero.',
        DeductPoints: 'Please enter points to be redeemed.',
        LessDeductPoints: 'Current reward balance is less than the point redemption requested.',
        DeductZeroPoints: 'Points redeemed should be greater than zero.'
    },
    Credential: {
        CurrentUsername: 'Please enter current user name.',
        NewUsername: 'Please enter new user name.',
        ConfirmUsername: 'Please enter confirm user name.',
        DoesNotMatchUsername: 'Confirmed user name does not match.',
        CurrentPassword: 'Please enter current password.',
        NewPassword: 'Please enter new password.',
        ValidPassword: 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.',
        ConfirmPassword: 'Please enter confirm password.',
        DoesNotMatchPassword: 'Confirmed password does not match.'
    }
};

export const RegEx = {
    ContactNumber: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
    UserName: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
    OnlyNumber: /^[0-9\b]+$/,
    EmailAddressOld: /^(?=[a-zA-Z0-9])(?!.*[\.+\-_]{2})([a-zA-Z0-9_+.-]+)@([a-zA-Z0-9_.]+)\.([a-zA-Z]{2,5})(?!\.)$/,
    EmailAddress: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    urlHasHttp: /^(?:f|ht)tps?\:\/\//,
    passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
};

export const PackageTypes = {
    Essential: 'Essential',
    Premium: 'Premium',
    MarketingImpactModel: 'Marketing'
};

export const PackageId = {
    Essential: 1,
    Premium: 2,
    MarketingImpactModel: 3
};

export const StateCodes = [
    { SatetName: 'Alabama', StateCode: 'AL' },
    { SatetName: 'Alaska', StateCode: 'AK' },
    { SatetName: 'Arizona', StateCode: 'AZ' },
    { SatetName: 'Arkansas', StateCode: 'AR' },
    { SatetName: 'California', StateCode: 'CA' },
    { SatetName: 'Colorado', StateCode: 'CO' },
    { SatetName: 'Connecticut', StateCode: 'CT' },
    { SatetName: 'Delaware', StateCode: 'DE' },
    { SatetName: 'Florida', StateCode: 'FL' },
    { SatetName: 'Georgia', StateCode: 'GA' },
    { SatetName: 'Hawaii', StateCode: 'HI' },
    { SatetName: 'Idaho', StateCode: 'ID' },
    { SatetName: 'Illinois', StateCode: 'IL' },
    { SatetName: 'Indiana', StateCode: 'IN' },
    { SatetName: 'Iowa', StateCode: 'IA' },
    { SatetName: 'Kansas', StateCode: 'KS' },
    { SatetName: 'Kentucky', StateCode: 'KY' },
    { SatetName: 'Louisiana', StateCode: 'LA' },
    { SatetName: 'Maine', StateCode: 'ME' },
    { SatetName: 'Maryland', StateCode: 'MD' },
    { SatetName: 'Massachusetts', StateCode: 'MA' },
    { SatetName: 'Michigan', StateCode: 'MI' },
    { SatetName: 'Minnesota', StateCode: 'MN' },
    { SatetName: 'Mississippi', StateCode: 'MS' },
    { SatetName: 'Missouri', StateCode: 'MO' },
    { SatetName: 'Montana', StateCode: 'MT' },
    { SatetName: 'Nebraska', StateCode: 'NE' },
    { SatetName: 'Nevada', StateCode: 'NV' },
    { SatetName: 'NewHampshire', StateCode: 'NH' },
    { SatetName: 'NewJersey', StateCode: 'NJ' },
    { SatetName: 'NewMexico', StateCode: 'NM' },
    { SatetName: 'NewYork', StateCode: 'NY' },
    { SatetName: 'NorthCarolina', StateCode: 'NC' },
    { SatetName: 'NorthDakota', StateCode: 'ND' },
    { SatetName: 'Ohio', StateCode: 'OH' },
    { SatetName: 'Oklahoma', StateCode: 'OK' },
    { SatetName: 'Oregon', StateCode: 'OR' },
    { SatetName: 'Pennsylvania', StateCode: 'PA' },
    { SatetName: 'RhodeIsland', StateCode: 'RI' },
    { SatetName: 'SouthCarolina', StateCode: 'SC' },
    { SatetName: 'SouthDakota', StateCode: 'SD' },
    { SatetName: 'Tennessee', StateCode: 'TN' },
    { SatetName: 'Texas', StateCode: 'TX' },
    { SatetName: 'Utah', StateCode: 'UT' },
    { SatetName: 'Vermont', StateCode: 'VT' },
    { SatetName: 'Virginia', StateCode: 'VA' },
    { SatetName: 'Washington', StateCode: 'WA' },
    { SatetName: 'WestVirginia', StateCode: 'WV' },
    { SatetName: 'Wisconsin', StateCode: 'WI' },
    { SatetName: 'Wyoming', StateCode: 'WY' }
];

export const LocationFeilds = {
    LocationName: 'LocationName',
    Address1: 'Address1',
    Address2: 'Address2',
    City: 'City',
    State: 'State',
    ZipCode: 'ZipCode',
    Budget: 'Budget',
    Year: 'Year',
    TimeZone: 'TimeZone'
};

export const MarketLocationFeilds = {
    LocationName: 'LocationName',
    Budget: 'Budget',
    NumberOfLocations: 'NumberOfLocations'
};

export const InvitationFields = {
    LegalName: 'LegalName',
    BusinessName: 'BusinessName',
    FirstName: 'FirstName',
    LastName: 'LastName',
    PhoneNumber: 'PhoneNumber',
    UserName: 'UserName',
    BusinessEmail: 'BusinessEmail',
    EmailSubject: 'EmailSubject',
    EmailBody: 'EmailBody',
    AdditionalEmail: 'AdditionalEmail'
};

export const ChannelTypes = {
    TV: 'TV',
    OnlineVideo: 'ONLINE VIDEO',
    Radio: 'RADIO',
    OnlineAudio: 'ONLINE AUDIO',
    NewsPaper: 'NEWSPAPER',
    Magzine: 'MAGAZINE',
    OutOfHome: 'OUT OF HOME',
    DigitalDisplay: 'DIGITAL DISPLAY',
    PaidSocial: 'PAID SOCIAL',
    PaidSearch: 'PAID SEARCH'
};

export const PreferenceIndicatorTypes = {
    Include: 'Include',
    Exclude: 'Exclude',
    NoPrefrence: 'No Preference'
};

export const LocationType = {
    IndividualLocation: 'IndividualLocation',
    GroupLocation: 'GroupLocation',
    NoSpecificLocation: 'NoSpecificLocation'
};

export const DicountPackageFeilds = {
    SetupFee: 'SetupFee',
    ModelingFee: 'ModelingFee',
    RecurringFee: 'RecurringFee',
    SearchAndSocialCommission: 'SearchAndSocialCommission',
    OtherMediaCommission: 'OtherMediaCommission'
};

export const TimeZones = [
    { id: 1, value: 'Eastern Time Zone (UTC-05:00)' },
    { id: 2, value: 'Central Time Zone (UTC-06:00 )' },
    { id: 3, value: 'Mountain Time Zone (UTC-07:00)' },
    { id: 4, value: 'Pacific Time Zone (UTC-08:00)' },
    { id: 5, value: 'Alaska Time Zone (UTC-09:00)' },
    { id: 6, value: 'Hawaii-Aleutian Time Zone (UTC-10:00)' }
];

export const activationForm = {
    activationCodeRequired: 'Please enter activation code',
    activationCodeType: 'Activation code must be a number'
};

export const createAccountForm = {
    passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    userNameRequired: 'Please enter user name.',
    temporaryPasswordRequired: 'Please enter temporary password.',
    newPasswordType: 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.',
    newPasswordRequired: 'Please enter new password.',
    confirmNewPasswordMatch: 'Passwords must match.',
    confirmNewPasswordRequired: 'Please enter confirm password.'
};

export const navigationTabList = {
    locations: 'locations',
    campaignDates: 'campaignDates',
    objectives: 'objectives',
    mediaMix: 'mediaMix',
    audiences: 'audiences',
    businessProfile: 'businessProfile',
    review: 'review'
};


export const ModelingObjectiveType = {
    Sales: 'Sales',
    Traffic: 'Traffic',
    Other: 'Other'
};

export const ForgotPasswordUserNameModalText = {
    HeaderText: {
        ForgotPassword: 'Password Reset',
        ForgotUsername: 'Forgot Username'
    },
    BodyText: {
        ForgotPassword: 'We have sent password reset instructions to the email address associated with your username. Didn’t get the password reset link? Check your spam folder',
        ForgotUsername: 'We have sent your username to your email. Didn’t get it? Check your spam folder'
    }
};

export const loginError = {
    userNameRequired: 'Please enter your username.',
    passwordRequired: 'Please enter your password.'
};

export const contactUs = {
    firstNameRequired: 'Please enter first name.',
    companyNameRequired: 'Please enter company name.',
    emailRequired: 'Please enter email address.',
    captchaRequired: 'Please enter captcha.',
    captchaIncorrect: 'Please enter correct captcha.',
    contactUsHeaderText: 'Thanks For Getting In Touch',
    contactUsBodyText: 'We appreciate your interest in Empower Media Agent. We will contact you soon to connect.'
};

export const campaignDatesError = {
    startDateRequired: 'Please select start date.',
    endDateRequired: 'Please select end date.',
    campaignOptionRequired: 'Please choose any one option.',
    invalidEssentialCampaignDates: 'Start date should be within a range of 10 to 26 weeks from the current date.',
    invalidPremiumCampaignDates: 'Start date should be within a range of 16 to 26 weeks from the current date.',
    minimumWeekError: 'Minimum 26 weeks required.',
    maximumWeekError: 'Maximum 52 weeks allowed.'
};

export const objectivesAndApproach = {
    objectiveOptions: {
        firstOption: 'I only want to drive short-term sales',
        secondOption: 'I lean toward short-term sales',
        thirdOption: 'I want to drive an equal balance of short-term sales & long-term brand equity',
        fourthOption: 'I lean toward long-term brand equity',
        fifthOption: 'I only want to drive long-term brand equity',
        premiumFirstOption: 'Sales',
        premiumSecondOption: 'Traffic'
    },

    approachOptions: {
        firstOption: 'I only want the most cost-efficient media buys possible. I don’t care where the ads run',
        secondOption: 'I lean toward cost efficiency',
        thirdOption: 'I want an equal balance of cost-efficiency and premium ad placement',
        fourthOption: 'I lean toward premium ad placements',
        fifthOption: 'I want to focus on premium ad placements - I don’t care about media cost-efficiency'
    },

    objectiveAndApproachRequired: 'Please choose any one option.'
};

export const reviewAndSign = {
    firstNameRequired: 'Please enter first name.',
    lastNameRequired: 'Please enter last name.',
    jobTitleRequired: 'Please enter job title.',
    iAgreeRequired: 'You must agree to the above Submission Agreement to continue.',
    discountAlertHeader: 'Are you sure?',
    discountAlertConfirm: 'Your promotional pricing is no longer applicable. Do you still want to continue?',
    discountConfirmText: 'Submit',
    discountCancelText: 'Cancel'
};

export const flightingTablePromotions = {
    promotionNameRequired: 'Please enter promotion name.',
    promotionLocationRequired: 'Please select locations',
    promotionStartDateRequired: 'Please select start date.',
    promotionEndDateRequired: 'Please select end date.',
    promotionEndDateInvalid: 'End date should not be less than start date.',
    promotionStartDateInvalid: 'Start date should not be greater than end date.',
    promotionDatesInvalid: '2 promotions exists on the selected date range. Please select different date.',
    promotionDatesExists: 'Promotion Exists with same Dates',
    deletePromotionHeaderText: 'Delete Promotion',
    deletePromotionBodyText: 'Are you sure you want to permanently delete this promotion?',
    deleteButtonText: 'Delete',
    cancelButtonText: 'Cancel'
};

export const flightingTableDarkDays = {
	darkdayDatesExists: 'Dark day Exists with same Dates'
};

export const noOfLocationOptions = () => {
    return [
        { value: 0, label: 'Select' },
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: 10, label: 10 }
    ];
};

export const getStates = () => {
    const options = [];
    options.push({ value: '', label: 'Select' });
    StateCodes.map((item) => {
        options.push({ value: item.StateCode, label: item.StateCode });
    });
    return options;
};

export const flightingTableLocations = {
    locationGroupNameRequired: 'Please enter location group name.',
    locationNameRequired: 'Please select at least one location.',
    channelNameRequired: 'Please select at least one channel.',
    emptyLocationGroups: 'Please add at least one location group.',
    uniqueLocationGroupName: 'Location group name should be unique.',
    deleteLocationHeaderText: 'Delete Location Groups & Channels',
    deleteLocationBodyText: 'Are you sure you want to permanently delete this location groups & channels?',
    deleteButtonText: 'Delete',
    cancelButtonText: 'Cancel'
};

export const campaignDeliverables = {
    deleteDeliverableHeaderText: 'Delete Deliverable',
    deleteDeliverableBodyText: 'Are you sure you want to permanently delete',
    deleteButtonText: 'Delete',
    cancelButtonText: 'Cancel'
};

export const creativeUploadTv = {
    adIdRequired: 'Please enter Ad-ID.',
    adNameRequired: 'Please enter Ad-ID name.',
    adDurationRequired: 'Please select duration.',
    adStartDateRequired: 'Please enter creative start date.',
    adEndDateRequired: 'Please enter creative end date.',
    adRotationRequired: 'Please enter rotation percentage.',
    adEndDateInvalid: 'End date should not be less than start date.',
    adStartDateInvalid: 'Start date should not be greater than end date.',
    emptyUploadOption: 'Please choose an option.',
    emptyUploadedFile: 'Please upload creative.',
    emptyUploadedUrl: 'Please provide URL.',
    emptyPreviousAssetNotes: 'Please provide previous campaign details.',
    invalidUrl: 'Please enter a valid url.',
    durationList: [
        { value: ':15', label: ':15' },
        { value: ':30', label: ':30' }
    ],
    loadingMessage: 'Creative upload in progress. This might take a few seconds.',
    clickThruURLRequired: 'Please enter URL.',
    headlineRequired: 'Please enter headline.',
    mainAdCopyRequired: 'Please enter main ad copy.',
    destinationUrlRequired: 'Please enter destination url.',
    requestRevisionReasonRequired: 'Please enter note.',
    invalidRecipientEmail: 'Please enter valid email address.',
    audioFileTypeError: 'Please upload .mp3 creatives.'
};

export const creativeChannelList = {
    tv: 'TV',
    onlineVideo: 'ONLINE VIDEO',
    radio: 'RADIO',
    onlineAudio: 'ONLINE AUDIO',
    newsPaper: 'NEWSPAPER',
    magazine: 'MAGAZINE',
    outOfHome: 'OUT OF HOME',
    digitalDisplay: 'DIGITAL DISPLAY',
    paidSocial: 'PAID SOCIAL',
    paidSearch: 'PAID SEARCH'
};

export const creativeUploadLocationAlert = {
    applyToAllLocations: {
        headerText: 'Are you sure?',
        bodyText: 'Applying your creative to all location groups will delete any creative that you may have already uploaded to any other location groups within this channel. You will no longer be able to add or edit creative for this channel in other location groups.',
        applyButtonText: 'Apply to all location groups',
        cancelButtonText: 'Cancel'
    },
    unableToApplyToAllLocations: {
        headerText: 'Unable To Apply To All Location Groups',
        bodyText: 'We’re sorry, since you have already submitted your creative for review for one or more of your other location groups, your are not able to apply this creative to all locations and you will need to manage your location groups individually.',
        applyButtonText: '',
        cancelButtonText: 'Cancel'
    },
    applyToThisLocationOnly: {
        headerText: 'Are you sure?',
        bodyText: 'You have creative applied from this location group to all location groups. Applying the creative to this location group only will remove it from all other location groups, and you will need to manage those location groups individually.',
        applyButtonText: 'Apply to This location only',
        cancelButtonText: 'Cancel'
    },
    deleteUploadedCreative: {
        headerText: 'Delete Creative',
        bodyText: 'Are you sure you want to permanently delete this creative?',
        applyButtonText: 'Delete',
        cancelButtonText: 'Cancel'
    },
    submitReviewAllLocationGroup: {
        headerText: 'Submit Creative for Review',
        bodyText1: 'Is this everything for',
        bodyText2: 'Since you are applying creative from one location group to all location groups, you will be submitting your creative for all location groups for this promotion. Once you submit your creative for review, you will not be able to upload additional items for this channel.',
        applyButtonText: 'Submit',
        cancelButtonText: 'Cancel'
    },
    submitReviewThisLocationGroupOnly: {
        headerText: 'Submit Creative for Review',
        bodyText1: 'Is this everything for',
        bodyText2: 'Once you submit your creative for review, you will not be able to upload additional items for this channel.',
        applyButtonText: 'Submit',
        cancelButtonText: 'Cancel'
    },
    requestRevision: {
        headerText: 'Request Revision',
        bodyText1: 'Thank you for submitting assets for',
        bodyText2: 'Unfortunately, the assets did not pass QA for the following reason: [admin to populate the reason for requesting revision here.] Please revisit the Media Agent portal and re-upload the assets as soon as possible. Thank you.',
        applyButtonText: 'Send',
        cancelButtonText: 'Cancel'
    },
    traffic: {
        headerText: 'Traffic',
        applyButtonText: 'Send',
        cancelButtonText: 'Cancel'
    },
    trafficConfirmation: {
        headerText: 'Traffic',
        bodyText: 'Are you sure you want to traffic even though there is no creative uploaded?',
        applyButtonText: 'Yes',
        cancelButtonText: 'Cancel'
    },
    unableToApplyToManyLocations: {
        headerText: 'Unable To Apply To Selected Location Groups',
        bodyText: 'We’re sorry, since you have already submitted your creative for review for one or more of your other location groups, your are not able to apply this creative to selected locations and you will need to manage your location groups individually.',
        applyButtonText: '',
        cancelButtonText: 'Cancel'
	},
	unableToCopyToLocation: {
		headerText: 'Unable To Copy To Selected Location Group',
		bodyText: 'We’re sorry, since you have already submitted your creative for review for one or more of your other location groups, your are not able to copy this creative to selected locations and you will need to manage your location groups individually.',
		applyButtonText: '',
		cancelButtonText: 'Ok'
	},
	copyCreativeInprogress: {
		headerText: 'Copying Creative',
		bodyText: '',
		applyButtonText: '',
		cancelButtonText: ''
	},
	copyCreativeCompleted: {
		headerText: 'Success!',
		bodyText: 'Your creative has been successfully copied.',
		applyButtonText: 'Done',
		cancelButtonText: ''
	}
};

export const campaignCreatives = {
    deleteCreativeHeaderText: 'Delete Creative Upload Stats',
    deleteCreativeBodyText: 'Are you sure you want to permanently delete',
    deleteButtonText: 'Delete',
    cancelButtonText: 'Cancel'
};

export const dragAndDropInfo = {
    // size in bytes (1024 * 1024 = 1MB)
    tv: {
        maxFile: 1,
		accept: '.mpeg, .mov, .mxf, .mp4',
		creativeLibraryFolder: ['Video'],
        maxSize: 500 * 1024 * 1024,
        maxSizeError: 'The file is too large. Maximum allowed file size is 500 mb.'
    },
    onlineVideo: {
        maxFile: 1,
        accept: '.mp4, .mov',
		creativeLibraryFolder: ['Video'],
		maxSize: 50 * 1024 * 1024,
        maxSizeError: 'The file is too large. Maximum allowed file size is 50 mb.'
    },
    radio: {
        maxFile: 1,
        accept: '.mp4, .mp3',
		creativeLibraryFolder: ['Audio'],
		maxSize: 2.4 * 1024 * 1024,
        maxSizeError: 'The file is too large. Maximum allowed file size is 2.4 mb.'
    },
    onlineAudio: {
        maxFile: 2,
        accept: '.mp3, .ogg',
		creativeLibraryFolder: ['Audio'],
		maxSize: 2.2 * 1024 * 1024,
        maxSizeError: 'The file is too large. Maximum allowed file size is 2.2 mb.'
    },
    newsPaper: {
        maxFile: 1,
        accept: '.pdf',
		creativeLibraryFolder: ['Images'],
		maxSize: 100 * 1024 * 1024,
        maxSizeError: 'The file is too large. Maximum allowed file size is 100 mb.'
    },
    magazine: {
        maxFile: 1,
        accept: '.pdf',
		creativeLibraryFolder: ['Images'],
		maxSize: 100 * 1024 * 1024,
        maxSizeError: 'The file is too large. Maximum allowed file size is 100 mb.'
    },
    outOfHome: {
        maxFile: 1,
        accept: '.jpeg, .psd, .pdf, .tiff, .eps, .ai, .jpg, .jpe, .jfif',
		creativeLibraryFolder: ['Images'],
		maxSize: 100 * 1024 * 1024,
        maxSizeError: 'The file is too large. Maximum allowed file size is 100 mb.'
    },
    digitalDisplay: {
        maxFile: 12,
        accept: '.jpeg, .gif, .jpg, .jpe, .jfif, .png, .html, .html5',
		creativeLibraryFolder: ['Images'],
		maxSize: 200 * 1024,
        maxSizeError: 'The file is too large. Maximum allowed file size is 200 kb.'
    },
    paidSocial: {
        maxFile: 10,
        accept: '.jpeg, .png, .mp4, .mov, .gif, .jpg, .jpe, .jfif',
		creativeLibraryFolder: ['Video', 'Images'],
		maxImageSize: 30 * 1024 * 1024,
        maxVideoSize: 100 * 1024 * 1024,
        maxSizeImageError: 'The file is too large. Maximum allowed file size is 30 mb.',
        maxSizeVideoError: 'The file is too large. Maximum allowed file size is 100 mb.'
    },
    document: {
        maxFile: 1,
        accept: '.pdf, .xls, .xlsx, .xlsb, .xlsm',
        maxSize: 100 * 1024 * 1024,
        maxSizeError: 'The file is too large. Maximum allowed file size is 100 mb.',
        wrongFileError: 'Please select pdf file only.'
    },
    documentSelectedOptions: {
        maxFile: 1,
        accept: '.pdf, .doc, .docx, .csv, .ppt, .pptx, .txt, .xls, .xlsx',
        maxSize: 100 * 1024 * 1024,
        maxSizeError: 'The file is too large. Maximum allowed file size is 100 mb.',
        wrongFileError: 'Please select pdf file only.'
    }
};

export const taskStatusDetails = {
    complete: {
        taskStatus: 'Completed',
        taskStatusId: 3
    },
    approved: {
        taskStatus: 'Approved',
        taskStatusId: 4
    }
};

export const TaskType = {
    DocumentSigning: 'Document signing',
    DocumentUploaded: 'Document uploaded',
    DatePicker: 'Date picker, future date only',
    MarkAsComplete: 'Mark as complete',
    ApproveRequestReject: 'Approve, Request Revisions or Reject',
    YesOrNo: 'Yes or No',
    AutoCompleted: 'Auto completed',
    TrafficOrRequestRevisions: 'Traffic or Request Revisions',
    UploadDocument: 'Upload Document',
    submitForReview: 'Submit for Review',
    UploadFileOrSendEmail: 'Upload file Or Send Email'
};

export const newTaskDetails = {
    ownerList: [
        { value: 'Customer', label: 'Customer' },
        { value: 'MA Team Member', label: 'Empower' }
    ],
    predecessorLag: 26,
    pastDueLag: 26,
    reminderLeadTime: 26,
    duration: 51,
    taskOwnerRequired: 'Please select task owner.',
    taskRequired: 'Please select predecessor task.',
    fixedStartDateRequired: 'Please select a fixed start date.',
    activeDescriptionRequired: 'Please enter description.',
    pastDueDescriptionRequired: 'Please enter description.',
    completedDescriptionRequired: 'Please enter description.',
    nonWorkingDayAlert: 'Start date selected is a non-working day. Would you like to proceed?',
    activeDescriptionMaxLength: 140,
    pastDueDescriptionMaxLength: 140,
    completedDescriptionMaxLength: 140,
    deleteTaskAlert: {
        headerText: 'Delete Task',
        bodyText: 'Are you sure you want to remove the task from the campaign flow ?',
        applyButtonText: 'Delete',
        cancelButtonText: 'Cancel'
    },
    holidayAlert: {
        headerText: 'Are you sure?',
        bodyText: 'Start date selected is a non-working day. Would you like to proceed?',
        applyButtonText: 'Yes',
        cancelButtonText: 'Cancel'
    }
};

export const deliverableType = {
    mim: 'MIM',
    mediaAgentPlan: 'MediaAgentPlan',
    mediaBuyReport: 'MediaBuyReport',
    digitalDashboard: 'DigitalDashboard',
    executiveReport: 'ExecutiveReport'
};

export const newMessage = {
    to: 'Media Agent Team',
    contentRequired: 'Please enter content.',
    subjectRequired: 'Please enter subject.',
    invalidEmail: 'Please enter valid email address.',
    toFieldRequired: 'Please include customer or add email address.'
};

export const RequestFlightingChange = {
    headerText: 'Request Flighting Changes',
    // eslint-disable-next-line no-useless-concat
    bodyText: 'Please briefly describe your flighting change request. We' + "'" + 'll follow up with you if we need more information. Thank you!',
    thankYouBodyText: 'Thank you. Your request has been sent.',
    sendButtonText: 'Send',
    cancelButtonText: 'Cancel',
    exitButtonText: 'Exit'
};

export const TaskIds = {
    ReviewMediaBuyReport: 16,
    EndOfCampaign: 36
};

export const creativeUploadLocationGroups = {
    selectSomeItems: 'Select Location'
};

export const PromotionClasses = {
	first: 1,
	second: 2,
	third: 3,
	four: 4,
	five: 5,
	six: 6,
	seven: 7,
	eigth: 8,
	nine: 9,
	zero: 0
};
